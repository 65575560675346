import axios from "axios";
import { API_URL } from "../../constants";
import { mapPriceToChartData } from "./helpers";

const LS_USER_INFO_KEY = "index_user_storage";
const LS_TOKEN_INFO_KEY = "index_token_storage";

axios.defaults.headers.get['Accept'] = 'application/json';

const httpClient = axios.create();


/**
 * Получение списка фильтров
 * @param params {Object}
 * @return {Promise<AxiosResponse<any>>}
 */
export function getFilter(params) {
  const mainUrl = `${API_URL}filters`;
  return httpClient
    .get(mainUrl, { params, cache: false })
    .then(response => response.data)
    .then(data => {
      const filter = data.reduce((acc, value) => {
        if (!acc[value.type]) {
          acc[value.type] = [];
        }
        acc[value.type].push(value.value);
        return acc;
      }, {});
      return filter;
    })
    .catch(e => {
      // eslint-disable-next-line
      console.error("getFilter:", e);
    });
}

/**
 * Получение индекса цен
 * @param params {Object}
 * @return {Promise<AxiosResponse<any>>}
 */
export function getPrices(params) {
  const mainUrl = `${API_URL}trends`;
  const token = getToken();
  const headers = {};
  if (token) {
    headers["Authorization"] = token;
  }
  return httpClient
    .get(mainUrl, { params, headers, cache: false })
    .then(response => mapPriceToChartData(response.data))
    .catch(e => {
      // eslint-disable-next-line
      console.error("getPrices:", e);
    });
}

/**
 * Сохнарить данные о пользователе в LocalStorage
 * @param user
 */
export function setUserInfo(user) {
  if (typeof localStorage === "undefined") return;
  localStorage.setItem(LS_USER_INFO_KEY, JSON.stringify(user));
}

/**
 * Получить данные о пользователе из LocalStorage
 * @return {{}|any}
 */
export function getUserInfo() {
  if (typeof localStorage === "undefined") return {};
  const userStr = localStorage.getItem(LS_USER_INFO_KEY);
  if (!userStr) return {};
  return JSON.parse(userStr);
}

/**
 * Проверка наличия данных о пользователе в LocalStorage
 * @return {boolean}
 */
export function checkUserInDB() {
  const user = getUserInfo();
  if (user && user.company && user.fio && user.email && user.phone) {
    return true;
  }
  return false;
}

/**
 * Сохранение токена в LocalStorage
 * @param token {String}
 * @return void
 */
export function setToken(token) {
  if (typeof localStorage === "undefined") return;
  localStorage.setItem(LS_TOKEN_INFO_KEY, token);
}

/**
 * Получение токена в LocalStorage
 * @return {string|null}
 */
export function getToken() {
  if (typeof localStorage === "undefined") return null;
  const token = localStorage.getItem(LS_TOKEN_INFO_KEY);
  return token;
}

/**
 * Отправка данных о пользователе
 * @param user
 * @return {Promise}
 */
export function sendUserInfo(user) {
  const mainUrl = `${API_URL}register`;
  return httpClient
    .post(mainUrl, {
      name: user.fio,
      INN: user.inn,
      email: user.email,
      phone: user.phone,
      company: user.company
    })
    .then(response => response.data)
    .then(data => setToken(data.token))
    .catch(e => {
      // eslint-disable-next-line
      console.error("sendUserInfo:", e);
    });
}
