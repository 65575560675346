import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BodyTypeSwitcher from '../BodyTypeSwitcher';
import TonnageVolume from '../TonnageVolume';
import TrafficType from '../TrafficType';
import LocationFrom from '../LocationFrom';
import Button from '../Button';
import { Wrapper, Header, GlobalStyles } from './styles';
import ModalContactForm from '../ModalContactForm';
import { getFiltersData, getTransportTypes } from '../../service/index.service';
// import DataPickerWrap from './DataPickerWrap';
import format from 'date-fns/format';

class TrendForm extends Component {
  state = {
    isLoading: false,
    showConfirm: false,
    isIndexOn: false,
    transportValue: '20т/82м3',
    transportValuePrev: '',
    transportFilterArr: [],
    bodyType: 'Тентованный',
    bodyTypesArr: [],
    trafficType: 'Междугородние',
    trafficFilterArr: [],
    loadingType: '',
    loadingLocation: '',
    loadingLocationFias: '',
    loadingFiasArr: [],
    unLoadingType: '',
    unLoadingLocation: '',
    unLoadingLocationFias: '',
    unLoadingFiasArr: [],
    transport: [],
    loadingCities: [],
    loadingDistricts: [],
    // unloadingCities: [],
    // unloadingDistricts: [],
    startDate: new Date(2018, 8, 1),
    endDate: new Date(),
  };

  async componentDidMount() {
    const transportTypes = await getTransportTypes();
      const newState = {
        transport: [],
      }
      newState['transport'] = transportTypes.data ?
      transportTypes.data.map(item => ({
          label: item.replace('м3', 'м\u00B3'),
          value: item
        }))
        : [];
      if (
        !newState['transport'].filter(item => {
          return item.value === this.state.transportValue;
        })
      ) {
        newState['transportValue'] = '';
      }
      this.setState(newState);

    this.reloadFilterData();
  }

  reloadFilterData(
    reloadLoadingLocation = true,
    reloadUnLoadingLocation = true
  ) {
    // this.setLoading(true);
    this.getNewFilterData(reloadLoadingLocation, reloadUnLoadingLocation);
  }


  getNewFilterData(
    reloadLoadingLocation = true,
    reloadUnLoadingLocation = true,
    // reloadBodyTypes = true,
    // reloadTransport = true,
    // reloadTrafficType = true
  ) {
    const formatStartDate = format(this.state.startDate, 'yyyy-MM-dd');
    const formatEndDate = format(this.state.endDate, 'yyyy-MM-dd');
    let loadCity = '';
    let loadDistrict = '';
    let unLoadCity = '';
    let unLoadDistrict = '';
    const bodyType = this.state.bodyType
    const transport = this.state.transportValue
    const scope = this.state.trafficType;
    if (this.state.loadingLocationFias !== undefined) {
      if (this.state.loadingType === 'city') {
        loadCity = this.state.loadingLocationFias;
      } else if (this.state.loadingType === 'district') {
        loadDistrict = this.state.loadingLocationFias;
      }
    }
    if (this.state.unLoadingLocationFias !== undefined) {
      if (this.state.unLoadingType === 'city') {
        unLoadCity = this.state.unLoadingLocationFias;
      } else if (this.state.unLoadingType === 'district') {
        unLoadDistrict = this.state.unLoadingLocationFias;
      }
    }
    const res = getFiltersData(transport, bodyType, scope, loadCity, unLoadCity, loadDistrict, unLoadDistrict, formatStartDate, formatEndDate)
    res.then(data => {
      const newData = data?.data;
      // console.log('newData', newData);
      if (newData?.length > 0) {
        let loadingCity = [];
        let unLoadingCity = [];
        let loadingDistrict = [];
        let unLoadingDistrict = [];
        let unLoadingFiasArr = [];
        let loadingFiasArr = [];
        let bodyTypesArr = [];
        let transportFilterArr = [];
        let trafficFilterArr = [];
        newData.map(item => {
          if (item?.type === "loading_city") {
            loadingCity.push({
              label: item?.value,
              value: item?.value,
            });
            loadingFiasArr.push({
              value: item?.value,
              key: item?.key,
            });
          }
          if (item?.type === "unloading_city") {
            unLoadingCity.push({
              label: item?.value,
              value: item?.value,
            });
            unLoadingFiasArr.push({
              value: item?.value,
              key: item?.key,
            });
          }
          if (item?.type === "loading_district") {
            loadingDistrict.push({
              label: item?.value,
              value: item?.value,
            });
            loadingFiasArr.push({
              value: item?.value,
              key: item?.key,
            });
          }
          if (item?.type === "unloading_district") {
            unLoadingDistrict.push({
              label: item?.value,
              value: item?.value,
            });
            unLoadingFiasArr.push({
              value: item?.value,
              key: item?.key,
            });
          }
          // if (item?.type === 'body') {
          //   bodyTypesArr.push(item?.value);
          // }
          // if (item?.type === 'transport') {
          //   transportFilterArr.push(item?.value);
          // }
          // if (item?.type === 'scope') {
          //   trafficFilterArr.push(item?.value);
          // }
        });
        if (reloadLoadingLocation) {
          if (reloadUnLoadingLocation) {
            const newState = {
              isLoading: false,
              unLoadingCities: unLoadingCity,
              unLoadingDistricts: unLoadingDistrict,
              loadingCities: loadingCity,
              loadingDistricts: loadingDistrict,
              unLoadingFiasArr: unLoadingFiasArr,
              loadingFiasArr: loadingFiasArr
            }
            // console.log('newState', newState);
            this.setState(newState);
          } else {
            const newState = {
              isLoading: false,
              loadingCities: loadingCity,
              loadingDistricts: loadingDistrict,
              loadingFiasArr: loadingFiasArr
            }
            // console.log('newState', newState);
            this.setState(newState);
          }

        } else {
          if (reloadUnLoadingLocation) {
            const newState = {
              isLoading: false,
              unLoadingCities: unLoadingCity,
              unLoadingDistricts: unLoadingDistrict,
              unLoadingFiasArr: unLoadingFiasArr,
            }
            // console.log('newState', newState);
            this.setState(newState);
          } else {
            const newState = {
              isLoading: false,
            }
            // console.log('newState', newState);
            this.setState(newState);
          }
        }
        // if (reloadBodyTypes) {
        //   const bodyNewState = {
        //     bodyTypesArr: bodyTypesArr,
        //   }
        //   this.setState(bodyNewState);
        // }
        // if (reloadTransport) {
        //   const transportNewState = {
        //     transportFilterArr: transportFilterArr,
        //   }
        //   this.setState(transportNewState);
        // }
        // if (reloadTrafficType) {
        //   const trafficNewState = {
        //     trafficFilterArr: trafficFilterArr,
        //   }
        //   this.setState(trafficNewState);
        // }
      } else {
        const newState = {
          isLoading: false,
          unLoadingCities: [],
          unLoadingDistricts: [],
          loadingCities: [],
          loadingDistricts: [],
          unLoadingFiasArr: [],
          loadingFiasArr: [],
        }
        this.setState(newState);
        // if (reloadBodyTypes) {
        //   const bodyNewState = {
        //     bodyTypesArr: [],
        //   }
        //   this.setState(bodyNewState);
        // }
        // if (reloadTransport) {
        //   const transportNewState = {
        //     transportFilterArr: [],
        //   }
        //   this.setState(transportNewState);
        // }
        // if (reloadTrafficType) {
        //   const trafficNewState = {
        //     trafficFilterArr: [],
        //   }
        //   this.setState(trafficNewState);
        // }
      }
    });
  }

  setLoading(isLoading) {
    this.setState({
      isLoading
    });
  }

  getBodyType() {
    return {
      body: this.state.bodyType
    };
  }
  getTransport() {
    if (!this.state.transportValue) {
      return {};
    }
    return {
      transport: this.state.transportValue
    };
  }
  getScope() {
    return {
      scope:
        this.state.trafficType === 'Междугородние' ? 'Междугородние' : 'Городские'
    };
  }
  getLoading() {
    if (this.state.isIndexOn) {
      return {};
    }
    if (this.state.loadingLocation) {
      if (this.state.loadingType === 'city') {
        return { loading_city: {value: this.state.loadingLocation, key: this.state.loadingLocationFias} };
      } else {
        return { loading_district: {value: this.state.loadingLocation, key: this.state.loadingLocationFias} };
      }
    }
    return {};
  }
  getUnLoading() {
    if (this.state.isIndexOn) {
      return {};
    }
    if (this.state.unLoadingLocation) {
      if (
        this.state.trafficType === 'Междугородние' &&
        this.state.unLoadingType === 'city'
      ) {
        return { unloading_city: {value: this.state.unLoadingLocation, key: this.state.unLoadingLocationFias} };
      } else if (this.state.trafficType === 'Междугородние') {
        return { unloading_district: {value: this.state.unLoadingLocation, key: this.state.unLoadingLocationFias} };
      }
    } else if (this.state.trafficType === 'Городские') {
      return { unloading_city: {value: this.state.loadingLocation, key: this.state.loadingLocationFias} }
    }
    return {};
  }

  /**
   * Получение значений фильтра из формы
   * @return {{}}
   */

  getFilter() {
    return {
      ...this.getBodyType(),
      ...this.getTransport(),
      ...this.getScope(),
      ...this.getLoading(),
      ...this.getUnLoading()
    };
  }

  /**
   * Событие на изменение поля "Тип кузова"
   * @param value
   */
  handleOnBodyTypeChange = value => {
    this.setState(
      {
        bodyType: value,
        // Сброс формы городов
        loadingType: '',
        loadingLocation: '',
        unLoadingType: '',
        unLoadingLocation: ''
      },
      () => {
        // const filter = {
        //   ...this.getBodyType(),
        //   ...this.getTransport(),
        //   ...this.getScope()
        //   // ...this.getLoading()
        // };

        // this.reloadFilterData(filter, false);
        this.reloadFilterData();
      }
    );
  };

  /**
   * Событие на изменение поля "Тоннаж/Объем"
   * @param value
   */
  handleOnTransportChanged = value => {
    this.setState(
      {
        transportValue: value,
        // Сброс формы городов
        // loadingType: "",
        loadingLocation: '',
        // unLoadingType: "",
        unLoadingLocation: ''
      },
      () => {
        // const filter = {
        //   ...this.getBodyType(),
        //   ...this.getTransport(),
        //   ...this.getScope(),
        //   ...this.getLoading()
        // };

        // this.reloadFilterData(filter);
        this.reloadFilterData();
      }
    );
  };

  /**
   * Событие на включение/выключение подсчета индекса
   * @param value
   */
  handleOnIndexChange = value => {
    this.setState({
      isIndexOn: value
    });
    this.getNewFilterData();
  };

  /**
   * Событие на изменение поля "Тип перевозок"
   * @param value
   */
  handleOnTrafficTypeChange = value => {
    this.setState(
      {
        trafficType: value,
        loadingType: 'city',
        loadingLocation: '',
        unLoadingType: '',
        unLoadingLocation: '',
        loadingLocationFias: '',
        unLoadingLocationFias: '',
      },
      () => {
        // const filter = {
        //   ...this.getBodyType(),
        //   ...this.getTransport(),
        //   ...this.getScope()
        // };
        // this.reloadFilterData(filter);
        this.reloadFilterData();
      }
    );
  };

  /**
   * Событие на изменение поля "Отправление"
   * @param value
   */
  handleOnLoadingChange = value => {

    let fiasObj = this.state.loadingFiasArr?.find(item => item.value === value.location);

    this.setState(
      {
        loadingType: value.type,
        loadingLocation: value.location,
        loadingLocationFias: fiasObj?.key,
        unLoadingLocation: '',
        unLoadingLocationFias: '',
      },
      () => {
        // const filter = {
        //   ...this.getBodyType(),
        //   ...this.getTransport(),
        //   ...this.getScope(),
        //   ...this.getLoading()
        // };
        // this.reloadFilterData(filter, false);
        this.reloadFilterData(false);
      }
    );
  };

  /**
   * Событие на изменение поля "Прибытие"
   * @param value
   */
  handleOnUnLoadingChange = value => {

    let fiasObj = this.state.unLoadingFiasArr?.find(item => item.value === value.location);

    this.setState({
      unLoadingType: value.type,
      unLoadingLocation: value.location,
      unLoadingLocationFias: fiasObj?.key,
    },
      () => {
        this.reloadFilterData(false, false);
      }
    );
  };

  checkFilter(filter) {
    if (!filter.transport || !filter.body) return false;
    if (this.state.isIndexOn) return true;
    if (!filter.loading_city && !filter.loading_district) return false;
    if (
      filter.scope === 'Междугородние' &&
      !filter.unloading_city &&
      !filter.unloading_district
    ) {
      return false;
    }
    return true;
  }

  handleOnFormSubmit = e => {
    const { onSubmit } = this.props;

    e.preventDefault();
    // this.handleOnConfirm();

    const filter = this.getFilter();
    if (!this.checkFilter(filter)) {
      if (typeof alert !== 'undefined') {
        // eslint-disable-next-line
        alert('Не все поля формы заполнены');
      }
      return;
    }
    filter.startDate = format(this.state.startDate, 'yyyy-MM-dd');
    filter.endDate = format(this.state.endDate, 'yyyy-MM-dd');
    // console.log(filter)
    onSubmit(filter);

    /*
    if (getToken()) {
      this.handleOnConfirm();
      return;
    }

    this.setState({
      showConfirm: true
    });
    */
  };

  handleOnCloseContact = () => {
    this.setState({
      showConfirm: false
    });
  };

  handleOnConfirm = () => {
    const { onSubmit } = this.props;
    const filter = this.getFilter();
    // const filter = {
    //   startDate: format(this.state.startDate, 'yyyy-MM-dd'),
    //   endDate: format(this.state.endDate, 'yyyy-MM-dd'),
    //   body: this.state.bodyType,
    //   scope: this.state.trafficType,
    //   transport: this.state.transportValue,
    // };
    this.setState({
      showConfirm: false
    });
    filter.startDate = format(this.state.startDate, 'yyyy-MM-dd');
    filter.endDate = format(this.state.endDate, 'yyyy-MM-dd');
    onSubmit(filter);
  };

  render() {
    const { getUserInfo, setUserInfo, sendUserInfo } = this.props;
    const {
      isIndexOn,
      showConfirm,
      bodyType,
      bodyTypesArr,
      transportValue,
      transport,
      transportFilterArr,
      trafficType,
      trafficFilterArr,
      loadingLocation,
      loadingCities,
      loadingDistricts,
      unLoadingCities,
      unLoadingDistricts,
      unLoadingLocation,
      // startDate,
      // endDate
    } = this.state;


    return (
      <Wrapper>
        <GlobalStyles />
        <>
          <Header>Сформируйте запрос для расчёта индекса цен</Header>
          <form autoComplete="off" onSubmit={this.handleOnFormSubmit}>
            {/* <DataPickerWrap
              startDate={startDate}
              endDate={endDate}
              setStartDate={(date) => this.setState({
                ...this.state,
                startDate: date
              })}
              setEndDate={(date) => this.setState({
                ...this.state,
                endDate: date
              })}
            /> */}
            <BodyTypeSwitcher
              value={bodyType}
              onChange={this.handleOnBodyTypeChange}
              bodyTypesArr={bodyTypesArr}
            />
            <TonnageVolume
              options={transport}
              value={transportValue}
              onChange={this.handleOnTransportChanged}
              onCheckBoxChange={this.handleOnIndexChange}
              isIndexOn={isIndexOn}
              transportFilterArr={transportFilterArr}
            />
            <TrafficType
              value={trafficType}
              onChange={this.handleOnTrafficTypeChange}
              trafficFilterArr={trafficFilterArr}
            />
            {!isIndexOn && (
              <>
                <LocationFrom
                  label={'Отправление'}
                  cities={loadingCities}
                  districts={loadingDistricts}
                  onChange={from => {
                    this.handleOnLoadingChange(from);
                  }}
                  cityOnly={trafficType !== 'Междугородние'}
                  value={loadingLocation}
                />
                {trafficType === 'Междугородние' && (
                  <LocationFrom
                    label={'Прибытие'}
                    cities={unLoadingCities}
                    districts={unLoadingDistricts}
                    onChange={to => {
                      this.handleOnUnLoadingChange(to);
                    }}
                    value={unLoadingLocation}
                  />
                )}
              </>
            )}
            <Button text={'Сформировать график индекса цен'} />
          </form>
        </>
        {showConfirm && (
          <ModalContactForm
            onClose={this.handleOnCloseContact}
            onConfirm={this.handleOnConfirm}
            getUserInfo={getUserInfo}
            setUserInfo={setUserInfo}
            sendUserInfo={sendUserInfo}
          />
        )}
      </Wrapper>
    );
  }
}

TrendForm.propTypes = {
  onSubmit: PropTypes.func,
  getFilter: PropTypes.func,
  getUserInfo: PropTypes.func,
  setUserInfo: PropTypes.func,
  sendUserInfo: PropTypes.func
};

export default TrendForm;
