import React, { Component, createRef } from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import TrendsHeader from "../components/HeadersCollection/TrendsHeader";
import { TrendsContentBlock } from "../components/ContentCollection";
import { TrendsFooter } from "../components/FooterCollection";
import Trends from "../containers/Trends";
import SEO from "../components/Seo";
import IconCard from "../components/IconCard";
import { TrendsButton } from "../components/Button/styles";

const List = styled.div``;

const ListItem = styled.div`
  display: flex;
  &:before {
    content: "\\2022";
    margin-right: 10px;
    font-size: 20px;
  }
`;

class TrendsPage extends Component {
  constructor(props) {
    super(props);
    this.trendsRef = createRef();
    this.aboutRef = createRef();
  }

  componentDidCatch(error, errorInfo) {
    if (window && window.Sentry) {
      window.Sentry.configureScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
      window.Sentry.captureException(error);
    }
  }

  handleOnAction = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: this.trendsRef.current.offsetTop + 80, behavior: 'smooth' });
    }
  };

  handleOnAbout = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: this.aboutRef.current.offsetTop + 80, behavior: 'smooth' });
    }
  };

  render() {
    return (
      <Layout>
        <SEO
          title={"Индекс цен"}
          keywords={[`traffic`, `траффик`, `грузоперевозки`]}
          description={
            "Индекс рыночных цен – это инструмент комплексного мониторинга рынка, который позволяет на системной основе оперативно отслеживать еженедельное изменение рыночных цен на автомобильные перевозки грузов по ключевым направлениям в России."
          }
        />
        <TrendsHeader />
        <main>
          <TrendsContentBlock
            title="Индекс цен"
            subtitle="Индекс рыночных цен – это инструмент комплексного мониторинга рынка, который позволяет на системной основе оперативно отслеживать еженедельное изменение рыночных цен на автомобильные перевозки грузов по ключевым направлениям в России."
            action="Рассчитать"
            about="Подробнее"
            onAction={this.handleOnAction}
            onAbout={this.handleOnAbout}
            image="/images/trends/image.png"
          />
          <div ref={this.trendsRef}>
            <Trends />
          </div>
          <div
            className="container"
            style={{ paddingTop: "120px" }}
            ref={this.aboutRef}
          >
            <IconCard
              title="Как работает расчёт"
              text={
                <>
                  Методика расчета индекса основана на еженедельном сборе
                  тарифов на рынке грузоперевозок и вычислении их
                  средневзвешенного значения. Полученные данные обрабатываются
                  математической моделью.
                  <br />
                  <br />
                  Индекс по каждому отдельному направлению (Город – Город)
                  рассчитывается как отношение средней ставки направления на
                  конкретной неделе к средней ставке по этому направлению на
                  первой (базисной) неделе.
                  <br />
                  <br /> Для расчета индекса в разрезах (Город – Округ, Округ –
                  Город, Округ – Округ и Средневзвешенный) учитывается вес
                  каждого конкретного направления (Город – Город), входящего в
                  выбранный срез. В качестве базового периода выступает вторая
                  неделя августа 2018 г.
                </>
              }
            />
            <IconCard
              title="Как использовать данные"
              text="Применяя показатель участники рынка смогут оценить «скорость», с которой движется изменение стоимости услуг грузоперевозок, общее направление движения (рост или падение) и динамику рынка в разрезе направлений,  прогнозировать увеличение или снижение стоимости перевозки, основываясь на показателях индекса в прошлых периодах."
              isRightAlign
            />
            <IconCard
              title="Грузовладельцам"
              text={
                <List>
                  <ListItem>
                    позволяет отслеживать изменение стоимости перевозок под
                    влиянием различных факторов (рост затрат на топливо, ввод
                    новых налогов, сезонные всплески);
                  </ListItem>
                  <ListItem>
                    помогает в планировании бюджета на логистику.
                  </ListItem>
                </List>
              }
            />
            <IconCard
              title="Перевозчикам"
              text={
                <>
                  <List>
                    <ListItem>
                      позволяет своевременно изменить стоимость услуг на
                      перевозки для сокращения простоя транспорта;
                    </ListItem>
                    <ListItem>
                      помогает выбрать наиболее выгодные направления географии
                      перевозок.
                    </ListItem>
                  </List>
                  <TrendsButton
                    onClick={this.handleOnAction}
                    primary
                    style={{ width: "270px", marginTop: "30px" }}
                  >
                    Рассчитать индекс цен
                  </TrendsButton>
                </>
              }
              isRightAlign
            />
          </div>
        </main>
        <div style={{ background: "rgb(245, 246, 252)", height: "120px" }} />
        <TrendsFooter
          title={
            <span>
              Traffic — провайдер грузоперевозок <br /> по России
            </span>
          }
          subtitle={
            <span>
              Цифровая логистическая платформа для владельцев грузов и
              транспортных компаний
            </span>
          }
        />
      </Layout>
    );
  }
}

export default TrendsPage;
