import moment from "moment";

const START_DATE = "2018-01-01T00:00:00Z";

/**
 * Получение массива дат по дням, по диапазону дат
 * @param trend {Number}
 * @param start {String}
 * @param end {String}
 * @param format {String}
 * @return {Array}
 */
export function getRangesByValues(trend, start, end, format = "DD.MM.YY") {
  let _start = moment(start);
  const _end = moment(end);
  const result = [];
  while (_start.isBefore(_end) || _start.isSame(_end)) {
    result.push({
      date: _start.format(format),
      trend: trend
    });
    /* Смещение по неделям */
    _start = _start.add(7, "days");
  }
  return result;
}

/**
 * Получение диапазона дат за месяц
 * @return {{start: string, end: string}}
 */
export function getLastMonthRange() {
  return {
    start: moment()
      .subtract(1, "month")
      .utc().format(),
    end: moment().utc().format(),
  };
}

/**
 * Получение диапазона дат за квартал
 * @return {{start: string, end: string}}
 */
export function getLastQuarterRange() {
  return {
    start: moment()
      .subtract(3, "months")
        .utc().format(),
    end: moment().utc().format(),
  };
}

/**
 * Получение диапазона дат за год
 * @return {{start: string, end: string}}
 */
export function getLastYearRange() {
  return {
    start: moment()
      .subtract(1, "year")
        .utc().format(),
    end: moment().utc().format(),
  };
}

/**
 * Получение диапазона дат с начала года
 * @return {{start: string, end: string}}
 */
export function getFromYearBeginRange() {
  return {
    start: moment()
      .startOf("year")
      .utc()
      .format(),
    end: moment()
      .utc()
      .format()
  };
}

/**
 * Получение диапазона дат за все время
 * @return {}
 */
export function getAllTimeRange() {
  return {
    start: moment(START_DATE).utc().format(),
    end: moment().utc().format(),
  };
}

/**
 * "Подгонка" фильтра по дататам под данные, которые есть
 * @param dateRange
 * @param prices
 * @return {Object}
 */
export function compareDateRangeAndPrice(dateRange, prices) {
  if (typeof prices !== "object" || prices.length === 0) {
    return dateRange;
  }
  const start = moment(prices[0].date);
  const end = moment(prices[prices.length - 1].date);
  if (moment(dateRange.start).isBefore(start)) {
    dateRange.start = start;
  }
  if (moment(dateRange.end).isAfter(end)) {
    dateRange.end = end;
  }
  return {
    ...dateRange
  };
}

/**
 * Маппинг данных бека в массив данных для графика
 * @param priceList
 * @return {Array|*}
 */
export function mapPriceToChartData(priceList) {
  if (typeof priceList !== "object") {
    return [];
  }
  return priceList
    .map(({ trend, start, end }) => {
      return getRangesByValues(trend, start, end, "YYYY-MM-DD");
    })
    .reduce((acc, value) => {
      return [...acc, ...value];
    }, []);
}
