import React, { Component } from "react";
import styled from "styled-components";
import TrendForm from "../../components/TrendForm";
import TrendChartWrapper from "../../components/TrendChartWrapper";
import {
  // getFilter,
  // getPrices,
  getUserInfo,
  setUserInfo,
  sendUserInfo
} from "./services";
import {
  getLastMonthRange,
  getLastQuarterRange,
  getFromYearBeginRange,
  getLastYearRange,
  getAllTimeRange,
  compareDateRangeAndPrice
} from "./helpers";

const Wrapper = styled.section`
  width: 100%;
  padding: 100px;
  background-color: var(--smoke);
`;

class Trends extends Component {
  state = {
    showChart: false,
    filter: {}
  };

  handlerOnChartShow = filter => {
    this.setState({
      showChart: true,
      filter
    });
  };

  handleOnFormShow = () => {
    this.setState({
      showChart: false
    });
  };

  render() {
    const { showChart, filter } = this.state;
    const helpersCollection = {
      getLastMonthRange,
      getLastQuarterRange,
      getFromYearBeginRange,
      getLastYearRange,
      getAllTimeRange,
      compareDateRangeAndPrice
    };
    // console.log('filter in trends',filter);
    return (
      <Wrapper>
        {showChart ? (
          <TrendChartWrapper
            showForm={this.handleOnFormShow}
            filter={filter}
            helpers={helpersCollection}
            // getPrices={getPrices}
          />
        ) : (
          <TrendForm
            onSubmit={this.handlerOnChartShow}
            //@todo больше не нужен
            // getFilter={getFilter}
            getUserInfo={getUserInfo}
            setUserInfo={setUserInfo}
            sendUserInfo={sendUserInfo}
          />
        )}
      </Wrapper>
    );
  }
}

export default Trends;
