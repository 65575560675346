import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleDropdown } from '../DropdownCollection';
import Label from '../Label';
import { Wrapper, Fields, Select } from './styles';
import CheckBoxInput from '../CheckBoxInput';

function TonnageVolume({ options, value, onChange, onCheckBoxChange, isIndexOn, transportFilterArr }) {
  //-------------------------------------------------------------------

  const [sortedOptions, setSortedOptions] = useState(options)

  // let sortedOptions = options;
  // TODO: пока бек не умеет сорировать данное поле
  useEffect(() => {
    if (Array.isArray(options)) {
      setSortedOptions(options.sort((a, b) => {
        let [_a] = String(a.label).split('т');
        let [_b] = String(b.label).split('т');
        _a = Number(_a.replace(',', '.'));
        _b = Number(_b.replace(',', '.'));
        if (isNaN(_a) || isNaN(_b)) {
          return 0;
        }
        return Number(_b) - Number(_a);
      }))
    }
  }, [options])

  // let filterOptions = sortedOptions.filter(item => transportFilterArr.includes(item.value));
  //-------------------------------------------------------------------
  return (
    <Wrapper>
      <Label text={'Тоннаж / Объём'} />
      <Fields>
        <Select skipBottomOffset={true}>
          <SimpleDropdown
            options={sortedOptions}
            onSelect={option => onChange(option.value)}
            value={value}
            placeholder=""
            isSearchable={false}
          />
        </Select>
        <CheckBoxInput
          text={'Средневзвешенный индекс'}
          onChange={onCheckBoxChange}
          hint="Совокупный показатель динамики изменения цены по всем направлениям,
        входящим в индекс"
          checked={isIndexOn}
        />
      </Fields>
    </Wrapper>
  );
}

TonnageVolume.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
  isIndexOn: PropTypes.bool,
  transportFilterArr: PropTypes.array
};

export default TonnageVolume;
