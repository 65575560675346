import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Label from "../Label";

const Wrapper = styled.div`
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  height: 60px;
  padding-right: 28px;
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1f2733;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: ${props =>
    props.isError ? "2px solid red" : "2px solid #d3dbe6"};
  background-color: #f5f8fc;
  font-size: 16px;
  line-height: 1.25;
`;

const ErrorText = styled.div`
  color: red;
  height: 25px;
  font-size: 12px;
  overflow: hidden;
`;

function SimpleInput({ value, onChange, text, placeholder, type, error }) {
  const handleOnChange = e => {
    onChange(e.target.value);
  };
  return (
    <Wrapper>
      <Label text={text} />
      <Input
        type={type}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        isError={!!error}
      />
      <ErrorText>{error}</ErrorText>
    </Wrapper>
  );
}

SimpleInput.defaultProps = {
  value: "",
  text: "",
  type: "text",
  placeholder: "",
  error: "",
  onChange: () => {}
};

SimpleInput.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string
};

export default SimpleInput;
