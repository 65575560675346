import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Chart from "react-apexcharts";
import "./styles.css"


const localeConfigurationObject = {
  name: "ru",
  options: {
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь"
    ],
    shortMonths: [
      "ЯНВ",
      "ФЕВ",
      "МАРТ",
      "АПР",
      "МАЙ",
      "ИЮНЬ",
      "ИЮЛЬ",
      "АВГ",
      "СЕНТ",
      "ОКТ",
      "НОЯ",
      "ДЕК"
    ]
  }
};

class TrendChart extends Component {
  state = {
    options: {
      chart: {
        id: "apexChart",
        locales: [localeConfigurationObject],
        defaultLocale: "ru",
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false,
          autoSelected: "pan"
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: true,
        borderColor: "#7a7f99",
        strokeDashArray: 3,
        position: 'back',
      },
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          show: true,
          formatter: function (value) {
            return (
              moment(value).format("DD.MM") +
              " &ndash; " +
              moment(value)
                .add(6, "days")
                .format("DD.MM.YY")
            );
          }
        },
        y: {
          formatter: (value) => {
            return value.toFixed(2);
          },
        },
        marker: {
          show: false
        }
      },
      stroke: {
        curve: ["straight"],
        width: 2,
        colors: ["#0044a3"],
        lineCap: "butt"
      },
      fill: {
        type: "gradient",
        gradient: {
          gradientToColors: ["#0044a3"],
          opacityFrom: 0.7,
          opacityTo: 0.1
        }
      },
      yaxis: {
        show: false,
        tickAmount: 8
      },
      xaxis: {
        categories: [],
        type: "datetime",
        labels: {
          style: {
            colors: ["#424b66"],
            fontSize: "12px",
            fontFamily: "GTPressuraPro"
          },
          datetimeFormatter: {
            month: `MMM
                    yyyy`
          },
          offsetX: -15
        },
        axisBorder: {
          show: true,
          color: "#d3dbe6",
          width: "100%",
          height: 1,
          offsetY: -1
        },
        axisTicks: {
          show: false,
          offsetX: -15
        }
      },
    },

    series: [
      {
        name: "",
        data: []
      }
    ],

    optionsForYear: {
      chart: {
        id: "apexChartYear",
        // locales: [...localeConfigurationObject],
        // defaultLocale: "ru",
        // toolbar: {
        //   autoSelected: "selection"
        // },
        brush: {
          enabled: true,
          target: "apexChart"
        },
        selection: {
          enabled: true,
          fill: {
            color: "#0044a3",
            opacity: 0.15
          },
          stroke: {
            width: 1,
            dashArray: 0,
            color: "#d3dbe6",
            opacity: 1
          },
          xaxis: {
            min: moment()
              .subtract(4, "months")
              .valueOf(),
            max: moment()
              .subtract(1, "months")
              .valueOf()
          }
        }
      },
      stroke: {
        curve: "straight",
        width: 0
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        colors: ["#d3dbe6"],
        type: "solid"
      },
      grid: {
        show: true,
        borderColor: "#d3dbe6",
        position: "back",
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        type: "datetime",
        categories: [],
        labels: {
          style: {
            colors: ["#424b66"],
            fontSize: "12px",
            fontFamily: "GTPressuraPro"
          },
          showDuplicates: false,
          formatter: function (value) {
            return moment(new Date(value)).format("YYYY")
          },
        },
        axisBorder: {
          show: true,
          color: "#d3dbe6",
          width: "100%",
          height: 2
        },
        axisTicks: {
          show: false,
          offsetX: -15
        }
      },
      yaxis: {
        show: false,
        tickAmount: 8,
      }
    },

    seriesForYear: [
      {
        name: "",
        data: []
      }
    ]
  };

  static getDerivedStateFromProps(props, state) {
    const { prices, range } = props;
    return {
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          categories:
            prices && prices.length > 0 ? prices.map(item => item.date) : []
        }
      },
      series: [
        {
          name: "",
          data:
            prices && prices.length > 0 ? prices.map(item => item.trend) : []
        }
      ],
      optionsForYear: {
        ...state.optionsForYear,
        xaxis: {
          ...state.optionsForYear.xaxis,
          categories:
            prices && prices.length > 0 ? prices.map(item => item.date) : []
        },
        chart: {
          ...state.optionsForYear.chart,
          selection: {
            ...state.optionsForYear.chart.selection,
            xaxis: {
              min:
                range && range.start
                  ? moment(range.start).valueOf()
                  : moment().valueOf(),
              max:
                range && range.end
                  ? moment(range.end).valueOf()
                  : moment().valueOf()
            }
          }
        }
      },
      seriesForYear: [
        {
          name: "",
          data:
            prices && prices.length > 0 ? prices.map(item => item.trend) : []
        }
      ]
    };
  }

  render() {
    return (
      <>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height="355"
          style={{ paddingTop: 60, paddingLeft: 25, paddingRight: 25 }}
        />

        <Chart
          options={this.state.optionsForYear}
          series={this.state.seriesForYear}
          type="area"
          height="120"
          style={{ paddingLeft: 25, paddingRight: 25 }}
        />
      </>
    );
  }
}

TrendChart.defaultProps = {
  prices: []
};

TrendChart.propTypes = {
  prices: PropTypes.array,
  range: PropTypes.object
};

export default TrendChart;
