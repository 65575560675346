import React, { useState, useEffect } from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
import SimpleInput from "../SimpleInput";
import Button from "../Button";
import MaskedInput from "../MaskedInput";

const WrapperFields = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineFields = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RowField = styled.div`
  width: 49%;
`;

function ContactForm({ onSubmit, getUserInfo, setUserInfo, sendUserInfo }) {
  const [fieldINN, setFieldINN] = useState("");
  const [fieldINNDone, setFieldINNDone] = useState(false);
  const [fieldCompany, setFieldCompany] = useState("");
  const [fieldFIO, setFieldFIO] = useState("");
  const [fieldEmail, setFieldEmail] = useState("");
  const [fieldPhone, setFieldPhone] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    const user = getUserInfo();
    if (user) {
      setFieldINN(user.inn || "");
      setFieldCompany(user.company || "");
      setFieldFIO(user.fio || "");
      setFieldEmail(user.email || "");
      setFieldPhone(user.phone || "");
    }
  }, []);

  useEffect(() => {
    if (fieldINN.length === 10 || fieldINN.length === 12) {
      setFieldINNDone(true);
    } else {
      setFieldINNDone(false);
    }
  }, [fieldINN]);

  const onSubmitForm = e => {
    e.preventDefault();
    setSubmitted(true);
    const user = {
      inn: fieldINN,
      company: fieldCompany,
      fio: fieldFIO,
      email: fieldEmail,
      phone: fieldPhone
    };
    if (fieldINNDone && fieldCompany && fieldFIO && fieldEmail && fieldPhone) {
      setUserInfo(user);
      sendUserInfo(user).then(() => {
        onSubmit(user);
      });
    }
  };

  return (
    <WrapperFields>
      <form onSubmit={onSubmitForm}>
        <SimpleInput
          text="ФИО"
          value={fieldFIO}
          onChange={setFieldFIO}
          placeholder="Например, Иванов Иван Иванович"
          error={isSubmitted && fieldFIO === "" ? "Обязательное поле" : ""}
        />
        <LineFields>
          <RowField>
            <MaskedInput
              text="Телефон"
              mask="+7 (999) 999-99-99"
              value={fieldPhone}
              onChange={setFieldPhone}
              placeholder={"+7"}
              error={
                isSubmitted && fieldPhone === "" ? "Обязательное поле" : ""
              }
            />
          </RowField>
          <RowField>
            <SimpleInput
              text="E-mail"
              value={fieldEmail}
              onChange={setFieldEmail}
              type="email"
              error={
                isSubmitted && fieldEmail === "" ? "Обязательное поле" : ""
              }
            />
          </RowField>
        </LineFields>
        <LineFields>
          <RowField>
            <SimpleInput
              text="ИНН"
              value={fieldINN}
              onChange={value => {
                const cleanValue = value.replace(/[^\d]/, "");
                if (cleanValue.length <= 12) {
                  setFieldINN(cleanValue);
                }
              }}
              error={isSubmitted && !fieldINNDone ? "Обязательное поле" : ""}
            />
          </RowField>
          <RowField>
            <SimpleInput
              text="Наименование компании"
              value={fieldCompany}
              onChange={setFieldCompany}
              error={
                isSubmitted && fieldCompany === "" ? "Обязательное поле" : ""
              }
            />
          </RowField>
        </LineFields>

        <Button text="Сформировать график индекса цен" />
      </form>
    </WrapperFields>
  );
}

ContactForm.defaultProps = {
  onSubmit: () => {}
};

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  getUserInfo: PropTypes.func,
  setUserInfo: PropTypes.func,
  sendUserInfo: PropTypes.func
};

export default ContactForm;
