import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Label from "../Label";
import InputMask from "react-input-mask";

const Wrapper = styled.div`
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  height: 60px;
  padding-right: 28px;
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1f2733;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: ${props =>
  props.isError ? "2px solid red" : "2px solid #d3dbe6"};
  background-color: #f5f8fc;
  font-size: 16px;
  line-height: 1.25;
`;

const ErrorText = styled.div`
  color: red;
  height: 25px;
  font-size: 12px;
  overflow: hidden;
`;

function MaskedInput({  mask, onChange, text, placeholder, type, error }) {
  const handleOnChange = e => {
    onChange(e.target.value);
  };
  return (
    <Wrapper>
      <Label text={text} />
      <InputMask mask={mask} onChange={handleOnChange} placeholder={placeholder} type={type}>
        {inputProps => <Input {...inputProps} />}
      </InputMask>
      <ErrorText>{error}</ErrorText>
    </Wrapper>
  );
}

MaskedInput.defaultProps = {
  value: "",
  text: "",
  type: "text",
  placeholder: "",
  error: "",
  mask: "",
  onChange: () => {}
};

MaskedInput.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string
};

export default MaskedInput;
