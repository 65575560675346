import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Location from "../Location";

function LocationFrom({ label, cityOnly, cities, districts, onChange, value }) {
  const [type, setType] = useState("city");
  const [location, setLocation] = useState(value);

  useEffect(() => {
    onChange({
      type: cityOnly ? "city" : type,
      location
    });
  }, [location]);

  useEffect(() => {
    setLocation(value);
  }, [value]);

  return (
    <>
      <Location
        label={label}
        cityOnly={cityOnly}
        locations={type === "city" || cityOnly ? cities : districts}
        location={location}
        onLocationChange={value => {
          setLocation(value);
        }}
        onTypeChange={value => {
          setLocation("");
          setType(value);
        }}
        type={cityOnly ? "city" : type}
      />
    </>
  );
}

LocationFrom.defaultProps = {
  cityOnly: false,
  value: ""
};

LocationFrom.propTypes = {
  label: PropTypes.string,
  cityOnly: PropTypes.bool,
  cities: PropTypes.array,
  districts: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default LocationFrom;
