import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { StaticButton } from '../ButtonsCollection';
import TrendChart from '../TrendChart';
import { getTrends } from '../../service/index.service';

const ChartWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(31, 39, 51, 0.1);

  & > div {
    overflow: hidden;

    &:nth-child(3) .apexcharts-yaxis text {
      fill: transparent;
    }
  }
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 20px 15px 30px;

  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(31, 39, 51, 0.1);
`;

const ChartHeaderTitle = styled.h3`
  margin: 0;
  font-size: 24px;
  color: #1f2733;
  line-height: 1.25;
`;

const ChartStaticButton = styled(StaticButton)`
  width: 200px;
  height: 50px;
  min-height: 50px;
  display: block;

  background-color: #0043a3;
  border: 2px solid #0043a3;
  color: #fff;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.5px;
  opacity: 1;
  transition: all 240ms ease-in-out;

  &:hover {
    opacity: 0.64;
  }
`;

const ChartLinkWrapper = styled.nav`
  display: flex;
  height: 25px;

  & a {
    margin: 30px 20px 0 0;
    font-size: 14px;
    color: rgba(31, 39, 51, 0.4);
    text-transform: uppercase;
    transition: all 240ms ease-in-out;

    &:first-child {
      margin-left: 30px;
    }

    &:hover {
      cursor: pointer;
      color: rgba(31, 39, 51, 1);
    }

    &.active {
      color: #0043a3;
    }
  }
`;

function TrendChartWrapper({
  showForm,
  filter,
  helpers,
  //  getPrices
}) {
  const {
    getLastMonthRange,
    getLastQuarterRange,
    getFromYearBeginRange,
    getLastYearRange,
    getAllTimeRange,
    compareDateRangeAndPrice
  } = helpers;

  const LINKS = [
    { id: 0, text: 'ЗА МЕСЯЦ', action: getLastMonthRange() },
    { id: 1, text: 'ЗА КВАРТАЛ', action: getLastQuarterRange() },
    { id: 2, text: 'С НАЧАЛА ТЕКУЩЕГО ГОДА', action: getFromYearBeginRange() },
    { id: 3, text: 'ЗА ГОД', action: getLastYearRange() },
    { id: 4, text: 'ЗА ВСЁ ВРЕМЯ', action: getAllTimeRange() }
  ];

  const headerRef = useRef(null);

  const [price, setPrice] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(getLastQuarterRange());
  /* Выставляем активным выбранный интревал (по умолчанию "ЗА КВАРТАЛ")*/
  const [isActive, setIsActive] = useState(1);

  const setDateRangeFilter = dateRange => {
    const range = compareDateRangeAndPrice(dateRange, price);
    setDateRange(range);
  };

  const getTrendData = useCallback(async () => {
    try {
      const res = await getTrends(filter?.transport, filter?.body, filter?.scope, filter?.loading_city?.key, filter?.unloading_city?.key, filter?.loading_district?.key, filter?.unloading_district?.key, filter?.startDate, filter?.endDate);
      const trendArr = [];
      if (res.data?.length > 0) {
        res.data.map(item => {
          let obj = {
            date: item.end,
            trend: item.trend
          }
          trendArr.push(obj);
        })
      }
      setPrice(trendArr);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: headerRef.current.offsetTop, behavior: 'smooth' });
    }
    setLoading(true);
    // console.log('filter', filter);
    //@todo старые тренды
    // getPrices({
    //   ...filter
    // }).then(data => {
    //   setPrice(data);
    //   setLoading(false);
    // });
    getTrendData();
  }, []);

  useEffect(() => {
    setDateRangeFilter(dateRange);
  }, [price]);

  const renderLocation = (
    loadCity,
    loadDistrict,
    unLoadCity,
    unLoadDistrict,
    scope
  ) => {
    if (!loadCity && !loadDistrict && !unLoadCity && !unLoadDistrict) {
      return `, ${scope.toLowerCase()}`;
    }
    return (
      <>
        {loadCity || loadDistrict ? (
          <>
            {', '}
            {loadCity ? loadCity : loadDistrict}
          </>
        ) : (
          ''
        )}
        {(unLoadCity || unLoadDistrict) && (loadCity != unLoadCity) ? (
          <> → {unLoadCity ? unLoadCity : unLoadDistrict}</>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <>
      <ChartHeader className={'container'} ref={headerRef}>
        <ChartHeaderTitle>
          {filter.body}
          {', '}
          {filter.transport.replace('м3', 'м³')}
          {renderLocation(
            filter.loading_city?.value,
            filter.loading_district?.value,
            filter.unloading_city?.value,
            filter.unloading_district?.value,
            filter.scope
          )}
        </ChartHeaderTitle>
        <ChartStaticButton onClick={showForm}>
          Создать новый запрос
        </ChartStaticButton>
      </ChartHeader>
      <ChartWrapper className={'container'}>
        <ChartLinkWrapper>
          {LINKS.map(link => (
            <a
              key={link.id}
              className={isActive === link.id ? 'active' : ''}
              onClick={e => {
                e.preventDefault();
                setDateRangeFilter(link.action);
                setIsActive(link.id);
              }}
            >
              {link.text}
            </a>
          ))}
        </ChartLinkWrapper>
        <TrendChart prices={isLoading ? [] : price} range={dateRange} />
      </ChartWrapper>
    </>
  );
}

TrendChartWrapper.propTypes = {
  showForm: PropTypes.func,
  filter: PropTypes.object,
  helpers: PropTypes.object,
  getPrices: PropTypes.func
};

export default TrendChartWrapper;
