import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Label from "../Label";
import Switcher from "../Switcher";
import { Wrapper } from "./styles";
import { getScopeTypes } from "../../service/index.service";

function TrafficType({ 
  value, 
  onChange, 
  // trafficFilterArr
 }) {

  const [movingTypes, setMovingTypes] = useState([]);
  // console.log(trafficFilterArr);

  const getScopeData = useCallback(async () => {
    try {
      const res = await getScopeTypes();
      let scopeArr = [];
      res.data.map(item => {
        let obj = {
          value: item,
          label: item,
        }
        scopeArr.push(obj);
      })
      setMovingTypes(scopeArr);
    } catch (error) {
      console.log(error);
    }
  }, [])

  useEffect(() => {
    getScopeData();
  }, [])

  return (
    <Wrapper>
      <Label text={"Тип перевозок"} />
      <Switcher
        options={movingTypes}
        value={value}
        onChange={onChange}
      />
    </Wrapper>
  );
}

TrafficType.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  trafficFilterArr: PropTypes.array,
};

export default TrafficType;
