import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 55px;
`;

export const Type = styled.div`
  width: 250px;
  margin-right: 30px;
  box-sizing: border-box;
`;

export const Fields = styled.div`
  display: flex;
`;

export const Field = styled.div`
  display: flex;
  flex-grow: 1;
`;