import React from "react";
import PropTypes from "prop-types";
import Label from "../Label";
import { Wrapper, Type, Fields, Field } from "./styles";
import Switcher from "../Switcher";
import { SimpleDropdown } from "../DropdownCollection";

const switcherOptions = [
  { value: "city", label: "Город" },
  { value: "district", label: "Округ" },
];

function Location({
  label,
  cityOnly,
  type,
  onTypeChange,
  locations = [],
  location,
  onLocationChange
}) {
  return (
    <Wrapper>
      <Label text={label} />
      <Fields>
        {!cityOnly && (
          <Type>
            <Switcher
              options={switcherOptions}
              onChange={onTypeChange}
              value={type}
            />
          </Type>
        )}
        <Field>
          <SimpleDropdown
            options={locations}
            value={location}
            onSelect={option => onLocationChange(option.label)}
            placeholder={type === "city" ? "Выберите город" : "Выберите округ"}
            isSearchable={false}
          />
        </Field>
      </Fields>
    </Wrapper>
  );
}

Location.propTypes = {
  label: PropTypes.string,
  cityOnly: PropTypes.bool,
  type: PropTypes.string,
  onTypeChange: PropTypes.func,
  locations: PropTypes.array,
  location: PropTypes.string,
  onLocationChange: PropTypes.func
};

export default Location;
