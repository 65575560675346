import styled from "styled-components";

export const TrendsButton = styled.button`
  width: 180px;
  background: ${props => (props.primary ? "#0044a3" : "#FFFFFF")};
  color: ${props => (props.primary ? "#FFFFFF" : "#0044a3")};
  border: ${props =>
  props.primary ? "2px solid #0044a3" : "2px solid #d3dbe6"};
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  height: 70px;
  border-radius: 5px;
  margin-right: 15px;

  &:hover {
    background-color: ${props =>
  props.primary ? "rgba(0, 68, 163, 0.8)" : "rgba(242, 242, 242, 0.8)"};
  }
`;