import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ContactForm from "../ContactForm";

const BackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  background: rgba(209, 209, 224, 0.6);
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  background: #ffffff;
  padding: 40px;
  width: 970px;
`;

const Close = styled.div`
  width: 21px;
  height: 21px;
  opacity: 0.6;
  background-image: url("/images/icons/ic-close.svg");
  position: absolute;
  top: -28px;
  right: -25px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const Header = styled.h4`
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 25px 0;
  padding: 0;
`;

const Text = styled.p`
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 50px 0;
`;

const Footer = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(31, 39, 51, 0.8);
`;

const FooterLink = styled.a`
  color: #0044a3;
`;

function ModalContactForm({
  onClose,
  onConfirm,
  getUserInfo,
  setUserInfo,
  sendUserInfo
}) {
  const handleOnKeyDown = e => {
    if (e.keyCode === 27) {
      onClose();
    }
  };
  const handleOnClick = () => {
    onClose();
  };

  return (
    <BackGround onKeyDown={handleOnKeyDown} onClick={handleOnClick}>
      <Container onClick={e => e.stopPropagation()}>
        <Close onClick={handleOnClick} />
        <Header>Укажите свои контактные данные</Header>
        <Text>
          Информация, собранная после отправки формы, используется для улучшения
          качества сервиса. Она нигде не публикуется и не доступна другим
          посетителям сайта.
        </Text>
        <ContactForm
          onSubmit={onConfirm}
          getUserInfo={getUserInfo}
          setUserInfo={setUserInfo}
          sendUserInfo={sendUserInfo}
        />
        <Footer>
          Нажимая кнопку &quot;Сформировать график индекса цен&quot;, я даю своё
          согласие на обработку и хранение своих персональных данных на
          условиях, определенных{" "}
          <FooterLink href="/files/terms-of-use.pdf" target="_blank">
            Пользовательским соглашением
          </FooterLink>{" "}
          и{" "}
          <FooterLink href="/files/privacy-policy.pdf" target="_blank">
            Политикой конфиденциальности
          </FooterLink>
          .
        </Footer>
      </Container>
    </BackGround>
  );
}

ModalContactForm.defaultProps = {
  onClose: () => {},
  onConfirm: () => {}
};

ModalContactForm.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  getUserInfo: PropTypes.func,
  setUserInfo: PropTypes.func,
  sendUserInfo: PropTypes.func
};

export default ModalContactForm;
